<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('ledger_detail_jbxx', { id: $route.query.id })">基本信息</span>
      <span @click="toUrl('ledger_detail_jsxx', { id: $route.query.id })">结算信息</span>
      <span @click="toUrl('ledger_detail_glqd', { id: $route.query.id })">关联渠道</span>
      <span @click="toUrl('ledger_detail_dzzb', { id: $route.query.id })">电子账簿</span>
      <span @click="toUrl('ledger_detail_cpxx', { id: $route.query.id })">产品信息</span>
      <span @click="toUrl('ledger_detail_bccl', { id: $route.query.id })" id="fzcolor">补充材料</span>
    </div>
    <div v-auth="'ELECTRLED:MCHCOM:MCHLIST:REPLENISH'">
      <div class="desc">
        <h5 class="jsxx">补充材料</h5>
        <el-button
            class="editBt"
            type="primary"
            @click="eidtBtn"
            v-auth="'ELECTRLED:MCHCOM:MCHLIST:REPLENISH/EDIT'"
            v-if="eidtBtnShow==false"
          >编辑</el-button>
      </div>
      <div class="bcclBox">
          <div class="head">
            <div class="line1">材料种类</div>
            <div class="line2">材料类型</div>
            <div class="line3">材料内容</div>
          </div>
          <div class="item">
            <div class="line1">店铺信息</div>
            <div class="line2">
              <div>店铺认证截图</div>
              <div>店铺货物截图</div>
              <div>支付流程截图</div>
              <div>店铺结算记录截图</div>
              <div>店铺链接</div>
            </div>
            <div class="line3" v-if="!eidtShow">
              <div>
                <a v-if="getData.storeAuthPic !='' && getData.storeAuthPic != null" href="javascript:;" @click="showImg(getData.storeAuthPic)">
                  <img :src="getData.storeAuthPic" class="approveImg">
                </a>
              </div>
              <div>
                <a v-if="getData.storeComPic !='' && getData.storeComPic != null" href="javascript:;" @click="showImg(getData.storeComPic)">
                  <img :src="getData.storeComPic" class="approveImg">
                </a>
              </div>
              <div>
                <a v-if="getData.storePayPic !='' && getData.storePayPic != null" href="javascript:;" @click="showImg(getData.storePayPic)">
                  <img :src="getData.storePayPic" class="approveImg">
                </a>
              </div>
              <div>
                <a v-if="getData.storeSetPic !='' && getData.storeSetPic != null" href="javascript:;" @click="showImg(getData.storeSetPic)">
                  <img :src="getData.storeSetPic" class="approveImg">
                </a>             
              </div>
              <div>
                <a v-if="getData.storeUrl !='' && getData.storeUrl != null" :href="getData.storeUrl" target="_black" style="color:#008aff">
                  {{ getData.storeUrl }}
                </a>
              </div>
            </div>
            <div class="line3" v-if="eidtShow">
              <div>
                <el-upload
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="storeAuthPicSuccess"
                >
                  <img
                    v-if="formData.storeAuthPic"
                    :src="formData.storeAuthPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.storeAuthPic" class="avatar-uploader-span" @click.stop="formData.storeAuthPic = ''"><i class="el-icon-close"></i></span>
                </el-upload>
              </div>
              <div>
                <el-upload 
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="storeComPicSuccess"
                >
                  <img
                    v-if="formData.storeComPic"
                    :src="formData.storeComPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.storeComPic" class="avatar-uploader-span" @click.stop="formData.storeComPic=''"><i class="el-icon-close"></i></span>
                </el-upload>
              </div>
              <div>
                <el-upload 
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="storePayPicSuccess"
                >
                  <img
                    v-if="formData.storePayPic"
                    :src="formData.storePayPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.storePayPic" class="avatar-uploader-span" @click.stop="formData.storePayPic=''"><i class="el-icon-close"></i></span>
                </el-upload>
              </div>
              <div>
                <el-upload 
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="storeSetPicSuccess"
                >
                  <img
                    v-if="formData.storeSetPic"
                    :src="formData.storeSetPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.storeSetPic" class="avatar-uploader-span" @click.stop="formData.storeSetPic=''"><i class="el-icon-close"></i></span>
                </el-upload>              
              </div>
              <div>
                <el-input v-model="formData.storeUrl" class="inputs" placeholder="输入店铺链接"></el-input>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="line1">APP信息</div>
            <div class="line2">
              <div>支付流程截图</div>
              <div>首页货品截图</div>
              <div>认证页截图</div>
              <div>APP 名称</div>
              <div>APP 账号</div>
              <div>APP 密码</div>
            </div>
            <div class="line3" v-if="!eidtShow">
              <div>
                <a v-if="getData.appPayPic !='' && getData.appPayPic != null" href="javascript:;" @click="showImg(getData.appPayPic)">
                  <img :src="getData.appPayPic" class="approveImg">
                </a>
              </div>
              <div>
                <a v-if="getData.appComPic !='' && getData.appComPic != null" href="javascript:;" @click="showImg(getData.appComPic)">
                  <img :src="getData.appComPic" class="approveImg">
                </a>             
              </div>
              <div>
                <a v-if="getData.appAuthPic !='' && getData.appAuthPic != null" href="javascript:;" @click="showImg(getData.appAuthPic)">
                  <img :src="getData.appAuthPic" class="approveImg">
                </a>               
              </div>
              <div>
                <p>{{ getData.appName }}</p>
              </div>
              <div>
                <p>{{ getData.appAccount }}</p>
              </div>
              <div>
                <p>{{ getData.appPasswordAPP }}</p>
              </div>
            </div> 
            <div class="line3" v-if="eidtShow">
              <div>
                <el-upload 
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="appPayPicSuccess"
                >
                  <img
                    v-if="formData.appPayPic"
                    :src="formData.appPayPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.appPayPic" class="avatar-uploader-span" @click.stop="formData.appPayPic=''"><i class="el-icon-close"></i></span>
                </el-upload>
              </div>
              <div>
                <el-upload 
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="appComPicSuccess"
                >
                  <img
                    v-if="formData.appComPic"
                    :src="formData.appComPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.appComPic" class="avatar-uploader-span" @click.stop="formData.appComPic=''"><i class="el-icon-close"></i></span>
                </el-upload>              
              </div>
              <div>
                <el-upload 
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="appAuthPicSuccess"
                >
                  <img
                    v-if="formData.appAuthPic"
                    :src="formData.appAuthPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.appAuthPic" class="avatar-uploader-span" @click.stop="formData.appAuthPic=''"><i class="el-icon-close"></i></span>
                </el-upload>              
              </div>
              <div>
                <el-input v-model="formData.appName" class="inputs" placeholder="输入APP名称"></el-input>
              </div>
              <div>
                <el-input v-model="formData.appAccount" class="inputs" placeholder="输入APP账号"></el-input>
              </div>
              <div>
                <el-input v-model="formData.appPasswordAPP" class="inputs" placeholder="输入APP密码"></el-input>
              </div>
            </div>                     
          </div>
          <div class="item">
            <div class="line1">公众号信息</div>
            <div class="line2">
              <div>支付流程截图</div>
              <div>首页货品截图</div>
              <div>认证页截图</div>
              <div>公众号名称</div>
            </div>
            <div class="line3" v-if="!eidtShow">
              <div>
                <a v-if="getData.mpPayPic !='' && getData.mpPayPic != null" href="javascript:;" @click="showImg(getData.mpPayPic)">
                  <img :src="getData.mpPayPic" class="approveImg">
                </a>       
              </div>
              <div>
                <a v-if="getData.mpComPic !='' && getData.mpComPic != null" href="javascript:;" @click="showImg(getData.mpComPic)">
                  <img :src="getData.mpComPic" class="approveImg">
                </a>                  
              </div>
              <div>
                <a v-if="getData.mpAuthPic !='' && getData.mpAuthPic != null" href="javascript:;" @click="showImg(getData.mpAuthPic)">
                  <img :src="getData.mpAuthPic" class="approveImg">
                </a>                
              </div>
              <div>
                <p>{{ getData.mpName }}</p>
              </div>
            </div>
            <div class="line3" v-if="eidtShow">
              <div>
                <el-upload 
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="mpPayPicSuccess"
                >
                  <img
                    v-if="formData.mpPayPic"
                    :src="formData.mpPayPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.mpPayPic" class="avatar-uploader-span" @click.stop="formData.mpPayPic=''"><i class="el-icon-close"></i></span>
                </el-upload>              
              </div>
              <div>
                <el-upload 
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="mpComPicSuccess"
                >
                  <img
                    v-if="formData.mpComPic"
                    :src="formData.mpComPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.mpComPic" class="avatar-uploader-span" @click.stop="formData.mpComPic=''"><i class="el-icon-close"></i></span>
                </el-upload>               
              </div>
              <div>
                <el-upload 
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="mpAuthPicSuccess"
                >
                  <img
                    v-if="formData.mpAuthPic"
                    :src="formData.mpAuthPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.mpAuthPic" class="avatar-uploader-span" @click.stop="formData.mpAuthPic=''"><i class="el-icon-close"></i></span>
                </el-upload>               
              </div>
              <div>
                <el-input v-model="formData.mpName" class="inputs" placeholder="输入公众号名称"></el-input>
              </div>
            </div>            
          </div>
          <div class="item">
            <div class="line1">H5 信息</div>
            <div class="line2">
              <div>支付流程截图</div>
              <div>首页货品截图</div>
              <div>H5 链接</div>
              <div>H5 账号</div>
              <div>H5 密码</div>
            </div>
            <div class="line3" v-if="!eidtShow">
              <div>
                <a v-if="getData.h5PayPic !='' && getData.h5PayPic != null" href="javascript:;" @click="showImg(getData.h5PayPic)">
                  <img :src="getData.h5PayPic" class="approveImg">
                </a>   
              </div>
              <div>
                <a v-if="getData.h5ComPic !='' && getData.h5ComPic != null" href="javascript:;" @click="showImg(getData.h5ComPic)">
                  <img :src="getData.h5ComPic" class="approveImg">
                </a>
              </div>
              <div>
                <a v-if="getData.h5Url !='' && getData.h5Url != null" :href="getData.h5Url" target="_black" style="color:#008aff">
                  {{ getData.h5Url }}
                </a>
              </div>
              <div>
                <p>{{ getData.h5Account }}</p>
              </div>
              <div>
                <p>{{ getData.h5Password }}</p>
              </div>
            </div>
            <div class="line3" v-if="eidtShow">
              <div>
                <el-upload 
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="h5PayPicSuccess"
                >
                  <img
                    v-if="formData.h5PayPic"
                    :src="formData.h5PayPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.h5PayPic" class="avatar-uploader-span" @click.stop="formData.h5PayPic=''"><i class="el-icon-close"></i></span>
                </el-upload>
              </div>
              <div>
                <el-upload 
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="h5ComPicSuccess"
                >
                  <img
                    v-if="formData.h5ComPic"
                    :src="formData.h5ComPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.h5ComPic" class="avatar-uploader-span" @click.stop="formData.h5ComPic=''"><i class="el-icon-close"></i></span>
                </el-upload>
              </div>
              <div>
                <el-input v-model="formData.h5Url" class="inputs" placeholder="输入H5链接"></el-input>
              </div>
              <div>
                <el-input v-model="formData.h5Account" class="inputs" placeholder="输入H5账号"></el-input>
              </div>
              <div>
                <el-input v-model="formData.h5Password" class="inputs" placeholder="输入H5密码"></el-input>
              </div>
            </div>            
          </div>
          <div class="item">
            <div class="line1">小程序信息</div>
            <div class="line2">
              <div>支付流程截图</div>
              <div>首页货品截图</div>
              <div>认证页截图</div>
              <div>小程序名称</div>
            </div>
            <div class="line3" v-if="!eidtShow">
              <div>
                <a v-if="getData.miniPayPic !='' && getData.miniPayPic != null" href="javascript:;" @click="showImg(getData.miniPayPic)">
                  <img :src="getData.miniPayPic" class="approveImg">
                </a>
              </div>
              <div>
                <a v-if="getData.miniComPic !='' && getData.miniComPic != null" href="javascript:;" @click="showImg(getData.miniComPic)">
                  <img :src="getData.miniComPic" class="approveImg">
                </a>
              </div>
              <div>
                <a v-if="getData.miniAuthPic !='' && getData.miniAuthPic != null" href="javascript:;" @click="showImg(getData.miniAuthPic)">
                  <img :src="getData.miniAuthPic" class="approveImg">
                </a>
              </div>
              <div>
                <p>{{ getData.miniName }}</p>
              </div>
            </div>
            <div class="line3" v-if="eidtShow">
              <div>
                <el-upload 
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="miniPayPicSuccess"
                >
                  <img
                    v-if="formData.miniPayPic"
                    :src="formData.miniPayPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.miniPayPic" class="avatar-uploader-span" @click.stop="formData.miniPayPic=''"><i class="el-icon-close"></i></span>
                </el-upload>
              </div>
              <div>
                <el-upload 
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="miniComPicSuccess"
                >
                  <img
                    v-if="formData.miniComPic"
                    :src="formData.miniComPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.miniComPic" class="avatar-uploader-span" @click.stop="formData.miniComPic=''"><i class="el-icon-close"></i></span>
                </el-upload>
              </div>
              <div>
                <el-upload 
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="miniAuthPicSuccess"
                >
                  <img
                    v-if="formData.miniAuthPic"
                    :src="formData.miniAuthPic"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.miniAuthPic" class="avatar-uploader-span" @click.stop="formData.miniAuthPic=''"><i class="el-icon-close"></i></span>
                </el-upload>
              </div>
              <div>
                <el-input v-model="formData.miniName" class="inputs" placeholder="输入小程序名称"></el-input>
              </div>
            </div>            
          </div>
          <div class="item">
            <div class="line1">代运营公司合作证明</div>
            <div class="line2">
              <div>代运营公司合作协议或合作说明</div>
            </div>
            <div class="line3" v-if="!eidtShow">
              <div>
                <a v-if="getData.businessContract !='' && getData.businessContract != null" :href="getData.businessContract" target="_black" style="color:#008aff">
                  {{ getData.businessContract }}
                </a>
              </div>
            </div>
            <div class="line3" v-if="eidtShow">
              <div>
                <el-upload 
                  class="avatar-uploader"
                  :before-upload="beforeUploadPDF"
                  drag
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="businessContractSuccess"
                  style="width: 94%;"
                >
                  <div v-if="formData.businessContract" class="avatar" style="width: 100%;display: flex;align-items: center;padding: 0px 4px;box-sizing: border-box;text-align: left;">{{ formData.businessContract }}</div>
                  <!--  :src="formData.businessContract" -->
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.businessContract" class="avatar-uploader-span" @click.stop="formData.businessContract=''"><i class="el-icon-close"></i></span>
                </el-upload>
              </div>
            </div>            
          </div>
      </div>
      <div class="bottom" v-if="eidtShow==true">
        <el-button class="back" type="primary" @click="backBtn">返回</el-button>
        <el-button @click="onSubmit" class="next" type="primary">保存</el-button>
      </div>
    </div>
    <!-- 点击查看图片 -->
    <div
        class="cover_view"
        v-if="showImgUrl !== ''"
        @click="showImgUrl = ''"
      >
        <a
          :href="showImgUrl"
          target="_blank"
        >
          <img
            :src="showImgUrl"
            alt="加载失败"
          />
        </a>
    </div>
    <div
        class="cover_view"
        v-if="showImgUrls.length > 0"
        @click="showImgUrls = []"
      >
        <i
          v-if="showImgUrlIndex > 0"
          class="el-icon-arrow-left left"
          @click.stop="showImgUrlIndex--"
        ></i>
        <i
          v-if="showImgUrlIndex < showImgUrls.length - 1"
          class="el-icon-arrow-right right"
          @click.stop="showImgUrlIndex++"
        ></i>
        <a
          :href="showImgUrls[showImgUrlIndex].url"
          target="_blank"
        >
          <img
            :src="showImgUrls[showImgUrlIndex].url"
            alt="加载失败"
          />
        </a>
    </div>    
  </div>
</template>
<script>
import {ledgerDetail ,putsupplementEidt } from "@/api/user/shopManager.js"; 
import { URL_API } from "@/utils/apiUrl.js";
import * as imageConversion from "image-conversion";
import md5 from "js-md5";
export default {
  data() {
    return {
      showImgUrl: "",
      showImgUrls: [], //查看多个图片
      showImgUrlIndex: 0, //查看多个图片切换
      getData:{
        storeAuthPic:"",//店铺认证截图 //店铺信息
        storeComPic:"",//店铺货物截图
        storePayPic:"",//支付流程截图
        storeSetPic:"",//店铺结算记录截图
        storeUrl:"",//店铺链接  
        appPayPic:"",//支付流程截图 //APP信息
        appComPic:"",//首页货品截图
        appAuthPic:"",//认证页截图
        appName:"",//APP名称
        appAccount:"",//APP账号
        appPasswordAPP:"",//APP密码
        mpPayPic:"",//支付流程截图 //公众号信息
        mpComPic:"",//首页货品截图
        mpAuthPic:"",//认证页截图
        mpName:"",//公众号名称
        h5PayPic:"",//支付流程截图 //H5 信息
        h5ComPic:"",//首页货品截图
        h5Url:"",//H5 链接
        h5Account:"",//H5 账号
        h5Password:"",//H5 密码 
        miniPayPic:"",//支付流程截图 //小程序信息
        miniComPic:"",//首页货品截图
        miniAuthPic:"",//认证页截图
        miniName:"",//小程序名称  
        businessContract:"",//代运营公司合作协议或合作说明  //代运营公司合作证明
      },  
      formData:{},    
      uploadUrl: URL_API.upload,
      md5,
      eidtShow:false,
      eidtBtnShow:false,
    };
  },
  computed: {

  },
  mounted() {

  },
  created() {
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      ledgerDetail(this.$route.query.id).then((res) => {
        if (res.resultStatus) { 
          this.getData = res.resultData.mchSupplement;            
        }
      });
    },
    //编辑
    eidtBtn(){
      this.eidtShow = true;
      this.eidtBtnShow = true;
      this.formData = JSON.parse(JSON.stringify(this.getData));
    },
    //返回
    backBtn(){
      this.eidtShow = false;
      this.eidtBtnShow = false;
    },
    //编辑保存
    onSubmit(){
      if(this.formData.storeAuthPic && this.formData.storeComPic && this.formData.storePayPic && this.formData.storeSetPic && this.formData.storeUrl){         
        }else if(this.formData.appPayPic && this.formData.appComPic && this.formData.appAuthPic && this.formData.appName && this.formData.appAccount && this.formData.appPasswordAPP ){         
        }else if(this.formData.mpPayPic && this.formData.mpComPic && this.formData.mpAuthPic && this.formData.mpName ){          
        }else if(this.formData.h5PayPic && this.formData.h5ComPic && this.formData.h5Url && this.formData.h5Account && this.formData.h5Password){       
        }else if(this.formData.miniPayPic && this.formData.miniComPic && this.formData.miniAuthPic && this.formData.miniName){         
        }else if(this.formData.businessContract){         
        }else{
          this.$message.error("补充材料至少上传一组");
          return false;
        }

      let data = {...this.formData};      
      putsupplementEidt(this.$route.query.id , data).then((res) => {
        if (res.resultStatus) { 
          this.$message.success("保存成功");     
          this.eidtShow = false;
          this.eidtBtnShow = false;  
          this.getDetail();   
        }
      });
    },
    // 浏览单个图片
    showImg(val) {
      this.showImgUrl = val;
    },
    // 浏览多个图片
    showImgs(val) {
      this.showImgUrls = val ? JSON.parse(val) : [];
      this.showImgUrlIndex = 0;
    },

  //代运营公司合作协议或合作说明
  businessContractSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.businessContract = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    //小程序信息
    miniPayPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.miniPayPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    miniComPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.miniComPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    miniAuthPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.miniAuthPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    //H5 信息
    h5PayPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.h5PayPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    h5ComPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.h5ComPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    //公众号信息
    mpPayPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.mpPayPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    mpComPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.mpComPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    mpAuthPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.mpAuthPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    //APP信息
    appPayPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.appPayPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    appComPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.appComPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    appAuthPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.appAuthPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 店铺信息
    storeAuthPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.storeAuthPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    storeComPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.storeComPic = res.resultData.fileLink;
      } else {
        return false;
      }     
    },
    storePayPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.storePayPic = res.resultData.fileLink;
      } else {
        return false;
      }     
    },
    storeSetPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.storeSetPic = res.resultData.fileLink;
      } else {
        return false;
      }     
    },
    beforeUpload(file) {
      this.isImge = true;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "jpeg" ||
        testmsg === "JPEG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      if (!extension) {
        this.$message.error("上传图片仅支持jpg/jpeg/png/bpm格式");
        this.isImge = false;
        return;
      }
      console.log(file.size,"压缩前")
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 2048).then((res) => {
          console.log(res,"压缩后")
          resolve(res);
        });
       
      });
    },   
    beforeUploadPDF(file) {
      this.isImge = true;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "pdf";
      if (!extension) {
        this.$message.error("上传图片仅支持pdf格式");
        this.isImge = false;
        return;
      }
      console.log(file.name,"压缩前")
      return new Promise((resolve) => {
          resolve(file);
      });
    },     
    // 页面跳转
    toUrl(name,query) {
      this.$router.push({
        name: name,
        query: {
          ...query,
        },
      });
    },

  },
  beforeDestroy() {

  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
.bottom {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 18px;
}
.approveImg{
  width: 60px;
    height: 60px;
    display: block;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
}
.bcclBox{
  border: 1px solid #D7DAE2;
  border-bottom: none;
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  margin-top: 20px;
}
.bcclBox .head , .bcclBox .item{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #D7DAE2;
  text-align: center;  
}
.bcclBox .head>div{
  height: 60px;
  line-height: 60px;
}
.bcclBox .head .line1 , .bcclBox .item .line1{
  width: 200px;
}
.bcclBox .head .line2 , .bcclBox .item .line2{
  width: 240px;
  border-right: 1px solid #D7DAE2;
  border-left: 1px solid #D7DAE2;
}
.bcclBox .head .line3 , .bcclBox .item .line3{
  width: calc(100% - 440px);
}
.bcclBox .item .line2>div , .bcclBox .item .line3>div{
  height: 80px;
  /* line-height: 80px; */
  border-bottom: 1px solid #D7DAE2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bcclBox .item .line3>div .inputs {
  width: 94%;
}

.bcclBox .item .line2>div:last-child , .bcclBox .item .line3>div:last-child{
  border-bottom: none;
}


.cover_view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.cover_view img {
  position: absolute;
  min-height: 200px;
  min-width: 200px;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.cover_view .left {
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
  color: #ffffff;
  font-size: 26px;
}

.cover_view .right {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  color: #ffffff;
  font-size: 26px;
}

.upload_wj {
  margin-bottom: 25px;
  width: 100%;
  padding-bottom: 25px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
}
.upload_wj_left {
  float: left;
  margin-left: 44px;
  width: 22%;
  height: 100%;
}
.upload_wj_left i {
  color: var(--themeColor);
  padding-right: 3px;
}
.upload_wj_right {
  float: left;
  margin-left: 200px;
  width: 22%;
  height: 100%;
}

.upload_wj h5 {
  padding: 29px 0 19px 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.upload_ph {
  width: 100%;
  height: 200px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  margin-bottom: 20px;
}


.upload_ph p {
  padding-top: 20px;
  padding-bottom: 20px;
}
.upload_ph p span {
  font-size: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.upload_ph p span i {
  color: var(--themeColor);
  padding-right: 3px;
}

.uploader1 {
  margin-left: 30px;
  float: left;
}

.uploader1 h4 {
  height: 52px;
  line-height: 52px;
  font-size: 14px;
  color: #000000;
}

.uploader1 h4 i {
  color: var(--themeColor);
  padding-right: 3px;
}

.avatar-uploader {
  width: 60px;
  height: 60px;
  background: rgba(246, 246, 246, 1);
  border: 1px solid rgb(241, 242, 244);
  border-radius: 4px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.avatar {
  width: 60px;
  height: 60px;
  display: block;
  margin: auto;
}
.cover_view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.cover_view img {
  position: absolute;
  min-height: 200px;
  min-width: 200px;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: var(--themeColor);
  border-color: var(--themeColor);
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: var(--title);
  font-size: 12px;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
}

.overAccount_ul_title i {
  color: var(--themeColor);
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>